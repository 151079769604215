<template>
  <section>
    <uspCartao :titulo="'Mandatos' + (nomclgpat && nomorgrsp ? ' - ' + nomclgpat + ' - ' + nomorgrsp : '')">
      <template slot="corpo">
        <uspSkeleton v-if="carregando > 0">
          <div class="w-100"><div></div></div>
          <div class="w-100"><div></div></div>
          <div v-for="index in 10" :key="index">
            <div class="w-60"></div>
            <div class="w-20"></div>
            <div class="w-20"></div>
          </div>
        </uspSkeleton>
        <template v-else>
          <div>
            <div class="w-60 pl-3 d-inline-block font-weight-bold">Membro</div>
            <div class="w-20 text-center d-inline-block font-weight-bold">In&iacute;cio do mandato</div>
            <div class="w-20 text-center d-inline-block font-weight-bold">Fim do mandato</div>
          </div>
          <div class="pb-4" v-for="(value, index) in lista" :key="value">
                <template v-for="(elem, index2) in value">
                <div :key="'titulo_' + ('000' + index).slice(-3) + ('000' + index2).slice(-3)" v-if="index2 == 0">
                    <div class="titulogrupo font-weight-bold">{{ ((elem.grupo1split || [])[2] || "")}}</div>
                </div>
                <div class="pt-2" :key="'suplente_' + ('000' + index).slice(-3) + ('000' + index2).slice(-3)" v-if="(elem.tipfncclg || '').toLowerCase() == 'suplente'">
                    <div class="w-60 pl-3 d-inline-block">
                    <i class="fas fa-share fa-rotate-270 mr-1"></i>
                    {{elem.tipmerfmt}} {{elem.nompes}} - <span class="suplenteLabel">{{elem.tipfncclg}}</span>
                    <span class="grupo2Label" v-if="elem.grupo2">
                        - {{elem.grupo2}}
                    </span>
                    </div>
                    <div class="w-20 h-100 text-center d-inline-block">{{elem.dtainimdtfmt}}</div>
                    <div class="w-20 h-100 text-center d-inline-block">{{elem.dtafimmdtfmt}}</div>
                </div>
                <div class="pt-4" :key="'titular_' + ('000' + index).slice(-3) + ('000' + index2).slice(-3)" v-else>
                    <div class="w-60 pl-3 d-inline-block">
                    {{elem.tipmerfmt}} {{elem.nompes}}
                    <span class="presidenteLabel" v-if="(elem.tipfncclg || '').toLowerCase() != 'titular'">
                        - {{elem.tipfncclg}}
                    </span>
                    <span class="grupo2Label" v-if="elem.grupo2 && elem.grupo2 != elem.tipfncclg">
                        - {{elem.grupo2}}
                    </span>
                    </div>
                    <div class="w-20 h-100 text-center d-inline-block">{{elem.dtainimdtfmt}}</div>
                    <div class="w-20 h-100 text-center d-inline-block">{{elem.dtafimmdtfmt}}</div>
                </div>
                </template>
          </div>
        </template>
      </template>
    </uspCartao>
  </section>
</template>

<script>
import NereuServico from "../dominio/NereuServico.js";
import eventBus from '@/eventBus'

const api = NereuServico.build({});
const grupo1Regex = new RegExp("(^[^:]+):(.+$)");

export default {
  name: "Mandato",

  props: {
    codclg: {
      type: String,
      default: "",
    },
    sglclg: {
      type: String,
      default: "",
    }
  },

  data: function() {
    return {
      erro: "",
      nomorgrsp: "",
      nomclgpat: "",
      lista: [],
      carregando: 0,
    }
  },

  mounted() {
    this.listar();
  },

  methods: {
    obterColegiado() {
      const self = this;
      const bean = {
        codclg: self.codclg,
        sglclg: self.sglclg,
      };
      self.carregando++;
      api
        .obterColegiado(bean)
        .then((resp) => {
          const clgBean = resp[0];
          self.nomorgrsp = clgBean.nomorgrsp;
          self.nomclgpat = clgBean.nomclgpat;
        })
        .catch((erro) => (self.erro = (erro || "").mensagem || erro))
        .finally(() => self.carregando--);
    },
    
    listar() {
      const self = this;
      const bean = {
        codclg: self.codclg,
        sglclg: self.sglclg,
        difref: "0",
      };
      self.carregando++;
      api
        .listar(bean)
        .then((resp) => {
          self.obterColegiado();
          self.lista = resp.reduce((a,b,i) => {console.log(i);
              let indiceGrupo = (i > 0 ? (a.length - 1) : 0);
              const ultimoElemento = (i > 0 ? (a[indiceGrupo])[(a[indiceGrupo]).length - 1] : {});console.log(ultimoElemento);
              b.grupo1split = (b.grupo1 || "").match(grupo1Regex);
              if (ultimoElemento.grupo1 != b.grupo1) {
                a[++indiceGrupo] = [];
              }
              (a[indiceGrupo]).push(b);
              console.log(a);
              return a;
            },
            []
          );
          console.log(resp);
          console.log(self.lista);
        })
        .catch((erro) => (self.erro = (erro || "").mensagem || erro))
        .finally(() => self.carregando--);
    }
  },

  watch: {
      erro: function(newValue) {
      if (newValue) {
        eventBus.mostrarMensagemToast({
              titulo: "Erro na listagem!",
              mensagem: this.erro,
              variante: 'danger'
            })
        this.erro = "";
      }
    },
  },
}

</script>

<style lang="scss" scoped>
@import "./src/componentes/estilos/temas.scss";

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .breadcrumb {
      a {
        color: map-get($tema, "usp-cabecalho-cor-do-texto");
      }
      .breadcrumb-item {
        &.active {
          color: map-get($tema, "usp-cabecalho-cor-do-texto");
        }
        & + .breadcrumb-item::before {
          color: map-get($tema, "usp-cabecalho-cor-do-texto");
        }
      }
    }

    .titulogrupo {
      color: map-get($tema, "usp-menu-lateral-arvore-item-destaque");
    }
    
    .presidenteLabel {
      color: map-get($tema, "usp-subelemento-cor-do-link");
    }
    
    .suplenteLabel {
      color: map-get($tema, "usp-subelemento-cor-do-link");
    }

    .grupo2Label {
      color: map-get($tema, "usp-subelemento-cor-do-texto");
    }
  }
}
</style>