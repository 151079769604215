import { PortalServico } from '@/utils/';

/**
 * @type {NereuServico}
 */
export default class NereuServico extends PortalServico {

  obterColegiado(params) {
    return this.post('/uspproc/listar/nereu/pubObterColegiado', params);
  }
  
  listar(params) {
    return this.post('/uspproc/listar/marteweb/pubListarParticipanteColeg', params);
  }

}